import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);


const HomeComponent = () => import('./pages/Home.vue');
const EventsDetailsComponent = () => import('./pages/EventsDetails.vue');
const EventsComponent = () => import('./pages/Events.vue');
const NobelComponent = () => import('./pages/Nobel.vue');
const ProgramsComponent = () => import('./pages/Programs.vue');
const ProgramDetailComponent = () => import('./pages/ProgramDetail.vue');
const AboutComponent = () => import('./pages/About.vue');

const NewsComponent = () => import('./pages/News.vue');
const ContactComponent = () => import('./pages/Contact.vue');
const NobelLaureatesComponent = () => import('./pages/NobelLaureates.vue');
const JobsComponent = () => import('./pages/Jobs.vue');
const DonateComponent = () => import('./pages/Donate/Donate.vue');
const DonatePayComponent = () => import('./pages/Donate/DonatePay.vue');
const DonateSuccessComponent = () => import('./pages/Donate/Success');

const TeamComponent = () => import('./pages/Team.vue');
const SpecialJuryComponent = () => import('./pages/SpecialJury.vue');
const FilmsComponent = () => import('./pages/Films.vue');
const FilmDetailsComponent = () => import('./pages/FilmDetails.vue');
const VisitorComponent = () => import('./pages/Visitor.vue');
const FilmsNewsComponent = () => import('./pages/FilmsNews.vue');
const BillionActsComponent = () => import('./pages/BillionActs.vue');

const OfficialRulesComponent = () => import('./pages/OfficialRules.vue');
const ShopComponent = () => import('./pages/Shop.vue');
const ClubPageComponent = () => import('./pages/ClubPage.vue');
const GeneralPrivacyComponent = () => import('./pages/Policy/GeneralPrivacy');
const DonorPrivacyComponent = () => import('./pages/Policy/DonorPrivacy');


export default new Router({
    mode: 'history',
    scrollBehavior: function (to) {
        if (to.hash) {
            return {
                selector: to.hash
            }
        }
    },
    routes: [
        {
            path: '/',
            name: 'home',
            meta: {
                layout: 'main',
                auth: true,
                title: 'Home'
            },
            component: HomeComponent,
        },
        {
            path: '/event/:url',
            name: 'event',
            meta: {
                auth: true,
                title: 'Event'
            },
            props: true,
            component: EventsDetailsComponent,
        },
        {
            path: '/events',
            name: 'events',
            meta: {
                auth: true,
                title: 'Events'
            },
            component: EventsComponent,
        },
        {
            path: '/nobel/:url',
            name: 'nobel',
            meta: {
                auth: true,
                title: 'Nobel'
            },
            props: true,
            component: NobelComponent,
        },
        {
            path: '/programs',
            name: 'programs',
            meta: {
                title: 'Programs'
            },
            component: ProgramsComponent,
        },
        {
            path: '/program/:url',
            name: 'program',
            meta: {
                title: 'Program'
            },
            props: true,
            component: ProgramDetailComponent,
        },
        {
            path: '/about',
            name: 'about',
            meta: {
                title: 'About'
            },
            props: true,
            component: AboutComponent,
        },
        {
            path: '/news/:offset',
            name: 'news',
            meta: {
                title: 'News'
            },
            props: true,
            component: NewsComponent,
        },
        {
            path: '/contact',
            name: 'contact',
            meta: {
                title: 'Contact'
            },
            component: ContactComponent,
        },
        {
            path: '/about-our-laureates',
            name: 'about-our-laureates',
            meta: {
                title: 'Nobel Laureates'
            },
            component: NobelLaureatesComponent,
        },
        {
            path: '/jobs',
            name: 'jobs',
            meta: {
                title: 'Jobs'
            },
            component: JobsComponent,
        },
        {
            path: '/donate',
            name: 'donate',
            meta: {
                title: 'Donate'
            },
            component: DonateComponent,
        },
        {
            path: '/donate/:price',
            name: 'donate-pay',
            meta: {
                title: 'Pay'
            },
            component: DonatePayComponent,
        },
        {
            path: '/donate-success/:name',
            name: 'donate-success',
            meta: {
                title: 'Thank You'
            },
            component: DonateSuccessComponent,
        },
        {
            path: '/team',
            name: 'team',
            meta: {
                title: 'Team'
            },
            component: TeamComponent,
        },
        {
            path: '/peacejam-special-jury-award',
            name: 'PeaceJam Special Jury Award',
            component: SpecialJuryComponent,
        },
        {
            path: '/films',
            name: 'films',
            meta: {
               title: 'Films'
            },
            component: FilmsComponent,
        },
        {
            path: '/film/:url',
            name: 'film',
            meta: {
              title: 'Film'
            },
            component: FilmDetailsComponent,
        },
        {
            path: '/get-involved',
            name: 'get-involved',
            meta: {
                title: 'Get involved'
            },
            component: VisitorComponent,
        },
        {
            path: '/films-news',
            name: 'films-news',
            meta: {
                title: 'Films News'
            },
            component: FilmsNewsComponent,
        },
        {
            path: '/billion-acts',
            name: 'billion-acts',
            meta: {
                title: 'Billion Acts'
            },
            component: BillionActsComponent,
        },
        {
            path: '/official-rules',
            name: 'official-rules',
            meta: {
                title: 'Official Rules'
            },
            component: OfficialRulesComponent,
        },
        {
            path: '/shop',
            name: 'shop',
            meta: {
                title: 'Shop'
            },
            component: ShopComponent,
        },
        {
            path: '/get-involved/:slug',
            name: 'club',
            props: true,
            meta: {
                title: 'Club Page'
            },
            component: ClubPageComponent,
        },
        {
            path: '/dashboard',
            name: 'dashboard',
            component: () => location.href = 'https://www.flexiblesites.net/admin/#/login?project=peacejam'
        },
        {
            path: '/general-privacy',
            name: 'general-privacy',
            props: true,
            meta: {
                title: 'General Privacy Policy'
            },
            component: GeneralPrivacyComponent,
        },
        {
            path: '/donor-privacy',
            name: 'donor-privacy',
            props: true,
            meta: {
                title: 'Donor Privacy Policy'
            },
            component: DonorPrivacyComponent,
        },
        {
            path: '*',
            component: HomeComponent,
        }
    ]
})
