<template>
  <div>
    <section class="others-section">
      <div class="container">
        <div class="row d-flex justify-content-center">
          <div class="col-lg-4 col-md-6 background one">
            <div class="others-one-content">
              <div class="others-one-btn">
                <router-link to="/about-our-laureates">
                  <img src="../assets/img/about/nobel-icon.png" width="40" height="40" alt="">
                  Meet Our Nobels
                </router-link>
              </div>
            </div>
          </div>

          <div class="col-lg-4 col-md-6 background two">
            <div class="others-two-content">
              <div class="others-two-btn">
                <router-link to="/team">
                  <img src="../assets/img/about/team.png" width="40" height="40" alt="">
                  Meet Our Team
                </router-link>
              </div>
            </div>
          </div>

          <div class="col-lg-4 col-md-6 background three">
            <div class="others-one-content">
              <div class="others-one-btn">
                <router-link to="/get-involved/start-a-club#club-reconnect">
                  <img src="../assets/img/about/work-from-home.png" width="40" height="40" alt="">
                  Start A Club
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Start Footer Area -->
    <div class="footer-section">
      <div class="container">
        <div class="row">
          <div class="col-lg-3 col-md-6 col-sm-12">
            <div class="single-footer-widget">
              <div class="logo">
                <router-link to="/">
                  <img src="../assets/img/logo-min-white.png" width="240" height="67" alt="image">
                </router-link>
              </div>
              <ul class="footer-contact-info">
                <li>
                  <i class="fa fa-envelope"></i>
                  <a href="mailto:info@peacejam.org">info@peacejam.org</a>
                </li>
                <li>
                  <i class="fa fa-crosshairs"></i>
                  PeaceJam Foundation <br>
                  1031 33rd Street, Suite 174<br>
                  Denver CO 80205 <br>
                </li>
              </ul>
              <ul class="footer-bottom-social">
                <li>
                  <a href="https://twitter.com/PeaceJam" target="_blank" rel="noreferrer">
                    <i class="flaticon-twitter"></i>
                  </a>
                </li>
                <li>
                  <a href="https://www.facebook.com/PeaceJamFoundation" target="_blank" rel="noreferrer">
                    <i class="flaticon-facebook"></i>
                  </a>
                </li>
                <li>
                  <a href="https://www.linkedin.com/company/peacejam-foundation/" target="_blank" rel="noreferrer">
                    <i class="flaticon-linkedin"></i>
                  </a>
                </li>
                <li>
                  <a href="https://www.instagram.com/peacejamhq/" target="_blank" rel="noreferrer">
                    <i class="flaticon-instagram"></i>
                  </a>
                </li>
              </ul>
<!--              <div class="single-footer-widget facebook-widget">-->
<!--                <iframe src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FPeaceJamFoundation&tabs=timeline&width=300&height=380&small_header=true&adapt_container_width=true&hide_cover=false&show_facepile=true&appId" width="300" height="380" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>-->
<!--              </div>-->
            </div>
          </div>

          <div class="col-lg-5 col-md-6 col-sm-12 footer-center-section">
            <div class="single-footer-widget">
              <h3>Quick Link</h3>

              <ul class="quick-links">
                <li>
                  <i class="flaticon-right-arrow"></i>
                  <router-link to="/about">About</router-link>
                </li>

                <li>
                  <i class="flaticon-right-arrow"></i>
                  <router-link to="/events">Events</router-link>
                </li>

                <li>
                  <i class="flaticon-right-arrow"></i>
                  <router-link to="/programs">Programs</router-link>
                </li>

                <li>
                  <i class="flaticon-right-arrow"></i>
                  <router-link to="/billion-acts">Billion Acts</router-link>
                </li>

                <li>
                  <i class="flaticon-right-arrow"></i>
                  <router-link to="/films">Films</router-link>
                </li>

                <li>
                  <i class="flaticon-right-arrow"></i>
                  <router-link to="/contact">Offices</router-link>
                </li>

                <li>
                  <i class="flaticon-right-arrow"></i>
                  <router-link to="/jobs">Careers</router-link>
                </li>
              </ul>
            </div>
            <div class="single-footer-widget">
              <h3>I am a...</h3>

              <ul class="quick-links">
                <router-link tag="li" to="/get-involved?go=student">
                  <i class="flaticon-right-arrow"></i>
                  <a>Student</a>
                </router-link>
                <router-link tag="li" to="/get-involved?go=educator">
                  <i class="flaticon-right-arrow"></i>
                  <a>Educator</a>
                </router-link>
                <router-link tag="li" to="/get-involved?go=alumni">
                  <i class="flaticon-right-arrow"></i>
                  <a>Alumni</a>
                </router-link>
                <router-link tag="li" to="/get-involved?go=supporter">
                  <i class="flaticon-right-arrow"></i>
                  <a>Supporter</a>
                </router-link>
                <router-link tag="li" to="/get-involved?go=partner">
                  <i class="flaticon-right-arrow"></i>
                  <a>Partner</a>
                </router-link>
              </ul>
            </div>
          </div>

          <div class="col-lg-4 col-md-12 col-sm-12">
            <div v-if="locations_data === undefined && !locations_error"><Loading /></div>
            <MapFooter :locations="locations_data" v-else />
          </div>
        </div>
      </div>
    </div>
    <!-- End Footer Area -->

    <!-- Start Footer Bottom Area -->
    <div class="footer-bottom">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-7">
            <div class="footer-bottom-text">
              <p>
                © Copyright {{new Date().getFullYear()}}, PeaceJam, Inc. is a 501(c)(3) non-profit EIN 84-1349666, All Rights Reserved.
                <router-link to="/general-privacy" target="_blank" rel="noreferrer">Privacy Policy</router-link>, Powered by <a href="https://www.flexiblesites.com/" target="_blank" rel="noreferrer">FlexibleSites</a>
              </p>
            </div>
          </div>
          <div class="col-lg-5">
            <ul class="billion-acts footer-bottom-social d-flex align-items-center justify-content-lg-end justify-content-center">
              <li>
                <a class="billion-acts-logo" href="https://www.billionacts.org/" rel="noreferrer" target="_blank">
                  <img src="../assets/img/billionActs/billion_Acts.png" width="150" height="42" alt="">
                </a>
              </li>
              <!-- <li>
                <a href="https://twitter.com/peacejam" target="_blank">
                  <i class="flaticon-twitter"></i>
                </a>
              </li> -->
              <li>
                <a href="https://www.facebook.com/BillionActs/" target="_blank" rel="noreferrer">
                  <i class="flaticon-facebook"></i>
                </a>
              </li>
              <li>
                <a href="https://www.youtube.com/user/PeaceJamHQ" target="_blank" rel="noreferrer">
                  <i class="fa fa-youtube-play"></i>
                </a>
              </li>
              <li>
                <a href="https://instagram.com/billionacts" target="_blank" rel="noreferrer">
                  <i class="flaticon-instagram"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!-- End Footer Bottom Area -->

    <!-- Start Go Top Section -->
    <div class="go-top" :class="{'active': active}" @click="goTop">
      <i class="fa fa-chevron-up"></i>
      <i class="fa fa-chevron-up"></i>
    </div>
    <!-- End Go Top Section -->
  </div>
</template>

<script>
  import MapFooter from "./MapFooter";
  import useSWRV from "swrv";
  import api from "../services/api";
  const Loading = () => import("./Loading");

  export default {
    name: "VueFooter",
    components: {Loading, MapFooter},
    data() {
      return {
        active: false
      }
    },
    setup() {
      let {
        data: locations_data,
        error: locations_error,
        isValidating: locations_isValidating
      } = useSWRV(() => `items/locations?status=published&fields=id,address,country,email,image,phone,status,title,country_name.*,type.*`, api.fetcher);

      return {
        locations_data,
        locations_error,
        locations_isValidating
      }
    },
    mounted() {
      window.addEventListener('scroll', this.handleScroll, true);
    },
    methods: {
      handleScroll() {
        window.onscroll = () => {
          let top = document.documentElement.scrollTop || document.body.scrollTop;
          this.active = top > 200;
        }
      },
      goTop() {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth'
        });
      }
    }
  }
</script>

<style scoped lang="scss">
  .footer-center-section {
    display: flex;
    justify-content: space-around;
  }
  .facebook-widget {
    display: flex;
    justify-content: center;

    iframe {
      border-radius: 10px;
      border: none !important;
      overflow: hidden;
      background: #fff;
      box-shadow: 0 0 13px 5px rgba(0, 0, 0, .23);
      //margin-top: -30px;
      //margin-bottom: -30px;
    }
  }

  .billion-acts-logo {
    width: auto;
    height: auto;
    background-color: transparent;
    box-shadow: none;
    border: none;

    &:hover {
      background-color: transparent !important;
    }

    img {
      margin-top: -4px;
    }
  }

  .billion-acts.footer-bottom-social li a:hover {
    background-color: #31bfd7;
  }

  .others-section {
    background-color: #0c5460;
    overflow: hidden;

    .others-one-btn, .others-two-btn {
      position: initial;
      display: flex;
      justify-content: center;
    }

    a {
      display: block;
      border: none;
      text-align: center;
      position: relative;
      z-index: 2;
      font-size: 22px;
      color: #ffffff;
      margin-bottom: 0;
      border-bottom:0px !important;
      text-transform: initial;

      img {
        margin-right: 15px;
      }
    }

    &:before, &:after {
      content: none;
    }

    .background {
      position: relative;

      &:before {
        content: '';
        position: absolute;
        top: -30px;
        bottom: -30px;
        right: 0;
        left: 0;
        z-index: 1;
      }

      &.one:before {
        background-color: #31bfd7;
        left: -100000px;
      }

      &.two:before {
        background: linear-gradient(90deg, #31bfd7, #0fdbaf);
        left: 0;
      }

      &.three:before {
        background-color: #10dbaf;
        right: -10000px;
      }
    }
  }

  @media (max-width: 991px) {
    .facebook-widget {
      justify-content: center;

      iframe {
        margin-top: 0;
        margin-bottom: 0;
      }
    }

    .others-section {
      background-color: #31bfd7;

      .background:before {
        background: #31bfd7 !important;
      }
    }
  }

  @media (max-width: 767px) {
    .single-footer-widget {
      text-align: center;
    }
    .single-footer-widget .footer-contact-info {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .single-footer-widget .footer-contact-info i {
      position: initial;
      margin-right: 15px;
    }

    .others-section {
      .others-two-content {
        margin-bottom: 30px;
        text-align: center;
      }
    }
  }

  @media (max-width: 550px) {
    .footer-center-section {
      flex-direction: column;
      align-items: center;
    }
  }
</style>
