import Vue from 'vue';
import App from './App.vue';
import router from './router';
import axios from "axios";
import VueAxios from "vue-axios";
import VueMoment from 'vue-moment';
import AOS from 'aos';
import 'aos/dist/aos.css';
import VModal from 'vue-js-modal';
import Vuelidate from 'vuelidate';
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import VueTippy from "vue-tippy";
import VueSocialSharing from 'vue-social-sharing';
import VueCompositionAPI from '@vue/composition-api';
import VueNumber from 'vue-number-animation';
import VueLazyload from 'vue-lazyload';
Vue.use(VueNumber);
import CountryFlag from 'vue-country-flag';
import loading from "./assets/img/placeholder.gif";
import error from "./assets/img/default-img.jpg";

const http = axios.create({
    baseURL: 'https://flexiblesites.net/peacejam/'
});

window.axios = require('axios');
Vue.use(VueAxios, http, axios);
Vue.use(VueMoment);
Vue.use(VModal);
Vue.use(Vuelidate);
Vue.use(VueSweetalert2);
Vue.use(VueTippy);
Vue.use(VueSocialSharing);
Vue.use(VueCompositionAPI);
Vue.use(VueLazyload, {
  preLoad: 1.3,
  error: error,
  loading: loading,
  attempt: 1,
});
Vue.component('country-flag', CountryFlag);

new Vue({
  router,
  created () {
    AOS.init()
  },
  render: h => h(App),
}).$mount('#app');
