import { render, staticRenderFns } from "./MapFooter.vue?vue&type=template&id=0fa14d80&scoped=true&"
import script from "./MapFooter.vue?vue&type=script&lang=js&"
export * from "./MapFooter.vue?vue&type=script&lang=js&"
import style0 from "./MapFooter.vue?vue&type=style&index=0&id=0fa14d80&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0fa14d80",
  null
  
)

export default component.exports