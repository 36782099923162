<template>
  <div id="app">
    <VueNavBar />
    <router-view />
    <VueFooter />
  </div>
</template>

<script>

import VueNavBar from "./components/VueNavBar";
import VueFooter from "./components/VueFooter";

export default {
  name: 'App',
  components: {VueFooter, VueNavBar},
  watch: {
    '$route'(to) {
      document.title = 'PeaceJam' + (to.meta.title ? ' - ' + to.meta.title : '') || 'PeaceJam'
    }
  }
}
</script>

<style lang="css">
  /*Bootstrap Min CSS*/
  @import "~@/assets/css/bootstrap.min.css";
  /*Animate Min CSS*/
  @import "~@/assets/css/animate.min.css";
  /*Meanmenu CSS*/
  @import "~@/assets/css/meanmenu.css";
  /*Flaticon CSS*/
  @import "~@/assets/css/flaticon.css";
  /*Fontawesome CSS*/
  @import "~@/assets/css/font-awesome.min.css";
  /*Odometer CSS*/
  @import "~@/assets/css/odometer.css";
  /*Style CSS */
  @import "~@/assets/css/style.css";
  /*Responsive CSS*/
  @import "~@/assets/css/responsive.css";
</style>

<style lang="scss">

  .vue-map-legend-content {
    display: none;
  }
  .vue-map-legend-header {
    padding: 4px;
    border-radius: 1px;
    background-color: #FFF;
    box-shadow: 1px 1px 5px #CCC;
    font-size: 12px;
    border: 1px solid #CCC;
  }

  .footer-section g {
    stroke: #1a1b1e;
  }

  g[aria-labelledby="id-72-title"] {
    display: none;
  }

  #testimonials .owl-carousel.owl-loaded {
    padding-bottom: 50px;
  }

  .padding-50-pr {
    padding-top: 90px !important;
    padding-bottom: 75px !important;
  }

  .modal-wrapper {
    padding: 30px;
    position: relative;

    .section-title {
      margin: 0;
    }

    .description {
      margin-top: 20px;
    }

    .bio-name-wrapper {
      display: flex;
      align-items: center;

      .section-text {
        margin: 0;
        text-align: left;
        h2 {
          font-size: 35px;
          font-weight: 600;
        }

        h6 {
          color: #FC4040;
        }
      }
    }

    .close-btn {
      position: absolute;
      top: 15px;
      right: 15px;
      border-radius: 50%;
      border: 2px solid #444;
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      i {
        font-size: 20px;
        font-weight: 100;
      }
    }

    h1 {
      margin-bottom: 20px;
    }

    .modal-body {
      padding: 0;
      display: flex;
      flex-direction: column;
    }
  }

  .white-space-pre-wrap {
    white-space: pre-wrap;

    &.remove-white-space {
      white-space: normal;
    }

    a {
      color: #31bfd7;
      text-decoration: underline;
    }
  }

  .visitorContent-wrapper {
    .text-section {
      a {
        color: #31bfd7;
        text-decoration: underline;
      }
    }
  }

  .background-btn {
    font-size: 15px;
    color: #ffffff;
    font-weight: bold;
    display: inline-block;
    background: #7e72f2;
    padding: 10px 20px;
    border-radius: 5px;
    transition: .3s;

    &.green-btn {
      background: #12d9af;
    }

    &:hover {
      background-color: #289bad;
      color: #fff;
    }
  }

  #event {
    .white-space-pre-wrap {
      line-height: 0;

      p {
        line-height: 1.5;
        margin: 0;
        padding: 0;
      }

      ul, ol {
        line-height: 0;
        margin: 0;
        margin-left: 10px;
        padding: 0;

        li {
          line-height: 1.5;
        }
      }

    }
  }

  .image-wrap {
    width: 500px;
  }

  .image-wrap.l-right {
    float: right;
    clear: right;
    margin: 0 1% 1% 4%;
  }

  .image-wrap.l-left {
    float: left;
    clear: left;
    margin: 0 4% 1% 1%;
  }

  @media (max-width: 992px) {
    .image-wrap {
      float: inherit;
      margin: 0 auto !important;
      width: 100%;
    }
  }

  @media (max-width: 600px) {
    .vm--modal {
      width: 100% !important;
      top: 50% !important;
      left: 50% !important;
      transform: translate(-50%, -50%);
    }
  }
</style>
