<template>
  <div>
    <!-- Start Preloader Area -->
    <div class="preloader" v-if="preloader">
      <div class="preloader">
        <span></span>
        <span></span>
      </div>
    </div>
    <!-- End Preloader Area -->

    <!-- Start Navbar Area -->
    <div class="nav-area fixed-top" :class="{'is-sticky': isSticky}">
      <div class="navbar-area">
        <!-- Menu For Mobile Device -->
        <div class="mobile-nav mean-container">
          <div class="mean-bar">
            <a
              href="#"
              @click.prevent="mobileNav = !mobileNav"
              class="meanmenu-reveal"
              :class="{ meanclose2: mobileNav }"
            >
              <span v-if="!mobileNav"
                ><span><span></span></span
              ></span>
              <template v-else>X</template>
            </a>
            <nav class="mean-nav">
              <ul
                class="navbar-nav m-auto"
                :class="{ 'show-navbar': mobileNav }"
              >
                <li>
                  <div class="mobile-buttons-wrapper">
                    <router-link to="/shop" class="burger-menu">
                      Shop
                    </router-link>
                    <router-link to="/donate" class="burger-menu">
                      Donate
                    </router-link>
                  </div>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="javascript:;">
                    About
                    <i class="fa fa-angle-down"></i>
                  </a>

                  <ul class="dropdown-menu" :style="{'display': activeMenu.includes('about') ? 'block' : 'none'}">
                    <li class="nav-item">
                      <router-link to="/about">About Us</router-link>
                    </li>

                    <li class="nav-item">
                      <router-link to="/home#makeImpactArea">What We Do </router-link>
                    </li>

                    <li class="nav-item">
                      <router-link to="/about-our-laureates">About the Nobels</router-link>
                    </li>

                    <li class="nav-item">
                      <router-link to="/team">Our Team</router-link>
                    </li>

                    <li class="nav-item">
                      <router-link to="/jobs">Jobs</router-link>
                    </li>

                    <li class="nav-item">
                      <router-link to="/news/0">News</router-link>
                    </li>
                  </ul>
                  <a class="mean-expand" @click="meanCxpand('about')" href="#" style="font-size: 18px">+</a>
                </li>

                <li class="nav-item">
                  <a class="nav-link" href="javascript:;">
                    Get Involved
                    <i class="fa fa-angle-down"></i>
                  </a>


                  <ul class="dropdown-menu" :style="{'display': activeMenu.includes('get-involved') ? 'block' : 'none'}">
                    <li class="nav-item">
                      <router-link to="/get-involved">Overview</router-link>
                    </li>
                    <li class="nav-item">
                      <router-link to="/get-involved/start-a-club">Start a Club</router-link>
                    </li>
                    <li class="nav-item">
                      <router-link to="/programs">Programs</router-link>
                    </li>
                    <li class="nav-item">
                      <router-link to="/events">Attend an Event</router-link>
                    </li>
                    <li class="nav-item">
                      <router-link to="/billion-acts">Join Billion Acts of Peace</router-link>
                    </li>
                    <li class="nav-item">
                      <router-link to="/get-involved/reconnect">Alumni</router-link>
                    </li>
                    <li class="nav-item">
                      <router-link to="/films">Watch a Film</router-link>
                    </li>
                  </ul>
                  <a class="mean-expand" @click="meanCxpand('get-involved')" href="#" style="font-size: 18px">+</a>
                </li>

                <li class="nav-item">
                  <router-link to="/events" class="nav-link">
                    Events
                    <i class="fa fa-angle-down"></i>
                  </router-link>
                </li>

                <li class="nav-item">
                  <a class="nav-link" href="javascript:;">
                    Films
                    <i class="fa fa-angle-down"></i>
                  </a>

                  <ul class="dropdown-menu" :style="{'display': activeMenu.includes('films') ? 'block' : 'none'}">

                    <li class="nav-item">
                      <router-link to="/films-news"
                      >Films News</router-link>
                    </li>

                    <li class="nav-item">
                      <router-link to="/films"
                        >The Nobel Legacy Film Series</router-link
                      >
                    </li>

                    <li class="nav-item">
                      <router-link to="/peacejam-special-jury-award"
                        >Special Jury Award</router-link
                      >
                    </li>
                  </ul>
                  <a class="mean-expand" @click="meanCxpand('films')" href="#" style="font-size: 18px">+</a>
                </li>

                <li class="nav-item mean-last">
                  <router-link to="/contact" class="nav-link"
                    >Offices</router-link
                  >
                </li>
              </ul>
            </nav>
          </div>
          <router-link to="/" class="logo">
            <img src="../assets/img/logo-min-dark.png" width="240" height="67" alt="Logo" />
          </router-link>
        </div>

        <!-- Menu For Desktop Device -->
        <div class="main-nav">
          <div class="top-nav-bar">
            <div class="container">
              <div class="row">
                <div class="col d-flex justify-content-between">
                  <ul>
                    <li>
                      <a target="_blank" href="https://www.peacejam.org/" rel="noreferrer">PeaceJam</a>
                    </li>
                    <li>
                        <router-link to="/billion-acts">Billion Acts</router-link>
                    </li>
                    <li>
                      <a target="_blank" href="https://peacejam.thinkific.com/" rel="noreferrer">My Courses</a>
                    </li>
                    <li>
                       <router-link to="/shop"> Shop</router-link>
                    </li>
                  </ul>

                  <ul>
                    <li>
                      <a href="https://twitter.com/PeaceJam" target="_blank" rel="noreferrer">
                        <i class="flaticon-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a href="https://www.facebook.com/PeaceJamFoundation" target="_blank" rel="noreferrer">
                        <i class="flaticon-facebook"></i>
                      </a>
                    </li>
                    <li>
                      <a href="https://www.linkedin.com/company/peacejam-foundation/" target="_blank" rel="noreferrer">
                        <i class="flaticon-linkedin"></i>
                      </a>
                    </li>
                    <li class="mr-0">
                      <a href="https://www.instagram.com/peacejamhq/" target="_blank" rel="noreferrer">
                        <i class="flaticon-instagram"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <nav class="navbar navbar-expand-md">
            <div class="container">
              <router-link class="navbar-brand" to="/">
                <img src="../assets/img/logo-min-dark.png" width="240" height="67" alt="Logo" />
              </router-link>

              <div class="collapse navbar-collapse mean-menu">
                <ul class="navbar-nav m-auto">

                  <li class="nav-item">
                    <a class="nav-link" href="javascript:;">
                      About
                      <i class="fa fa-angle-down"></i>
                    </a>

                    <ul class="dropdown-menu">
                      <li class="nav-item">
                        <router-link to="/about">About Us</router-link>
                      </li>

                      <li class="nav-item">
                        <router-link to="/home#makeImpactArea">What We Do </router-link>
                      </li>

                      <li class="nav-item">
                        <router-link to="/about-our-laureates">About the Nobels</router-link>
                      </li>

                      <li class="nav-item">
                        <router-link to="/team">Our Team</router-link>
                      </li>

                      <li class="nav-item">
                        <router-link to="/jobs">Jobs</router-link>
                      </li>

                      <li class="nav-item">
                        <router-link to="/news/0">News</router-link>
                      </li>
                    </ul>
                  </li>

                  <li class="nav-item">
                    <a class="nav-link" href="javascript:;">
                      Get Involved
                      <i class="fa fa-angle-down"></i>
                    </a>

                    <ul class="dropdown-menu">
                      <li class="nav-item">
                        <router-link to="/get-involved">Overview</router-link>
                      </li>
                      <li class="nav-item">
                        <router-link to="/get-involved/start-a-club">Start a Club</router-link>
                      </li>
                      <li class="nav-item">
                        <router-link to="/programs">Programs</router-link>
                      </li>
                      <li class="nav-item">
                        <router-link to="/events">Attend an Event</router-link>
                      </li>
                      <li class="nav-item">
                        <router-link to="/billion-acts">Join Billion Acts of Peace</router-link>
                      </li>
                      <li class="nav-item">
                        <router-link to="/get-involved/reconnect">Alumni</router-link>
                      </li>
                      <li class="nav-item">
                        <router-link to="/films">Watch a Film</router-link>
                      </li>
                    </ul>
                  </li>

                  <li class="nav-item">
                    <router-link to="/events" class="nav-link active">
                      Events
                    </router-link>
                  </li>

                  <li class="nav-item">
                    <a class="nav-link" href="javascript:;">
                      Films
                      <i class="fa fa-angle-down"></i>
                    </a>

                    <ul class="dropdown-menu">
                      <li class="nav-item">
                        <router-link to="/films-news"
                        >Films News</router-link
                        >
                      </li>

                      <li class="nav-item">
                        <router-link to="/films"
                        >The Nobel Legacy Film Series</router-link
                        >
                      </li>

<!--                      <li class="nav-item">-->
<!--                        <router-link to="/peacejam-special-jury-award"-->
<!--                        >Special Jury Award</router-link-->
<!--                        >-->
<!--                      </li>-->
                    </ul>
                  </li>

                  <li class="nav-item">
                    <router-link to="/contact" class="nav-link"
                    >Offices</router-link
                    >
                  </li>
                </ul>

                <div class="others-option">
                  <div class="d-flex align-items-center">
                    <div class="option-item">
                      <router-link to="/shop" class="burger-menu">
                        <img src="../assets/img/shopping-bag.png" width="25" height="25" alt="">
                      </router-link>
                    </div>

                    <div class="option-item donate">
                      <DonateButton />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </nav>
        </div>
      </div>
    </div>
    <!-- End Navbar Area -->

  </div>
</template>

<script>
import DonateButton from "./DonateButton";
export default {
  name: "VueNavBar",
  components: {
    DonateButton,
  },
  props: ["contact"],
  data() {
    return {
      preloader: true,
      mobileNav: false,
      instFeed: [],
      isSticky: false,
      activeMenu: []
    };
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll, true);
    setTimeout(() => {
      this.preloader = false;
    }, 1000);
  },
  methods: {
    handleScroll() {
      let top = document.documentElement.scrollTop || document.body.scrollTop;
      this.isSticky = top > 120;
    },
    meanCxpand(item) {
      if(!this.activeMenu.includes(item)){
        this.activeMenu.push(item);
      }else{
        this.activeMenu.splice(this.activeMenu.indexOf(item), 1);
      }
    }
  },
  watch: {
    $route() {
      this.mobileNav = false;
    },
  },
};
</script>

<style scoped lang="scss">
.likeCount {
    visibility: hidden;
    position: absolute;
    min-height: -webkit-fill-available;
    display: inline-flex;
    align-items: center;
    vertical-align: middle;
    width: -webkit-fill-available;
    justify-content: center;
  }
  .instaTag:hover .likeCount{
      visibility: visible;
  }
  .mobile-buttons-wrapper {
    display: flex;

    a {
      width: 50% !important;
      color: #fff !important;
      display: flex !important;
      justify-content: center;

      &:first-child {
        background: #34bdd5;

        &.router-link-exact-active.router-link-active {
          background: #34bdd5 !important;
        }
      }

      &:last-child {
        background: #7d71ef;

        &.router-link-exact-active.router-link-active {
          background: #7d71ef !important;
        }
      }
    }
  }
@media (max-width: 991px) {
  .meanmenu-reveal {
    transition: 0s !important;
  }
  .meanmenu-reveal.meanclose2 {
    right: 0px;
    left: auto;
    text-align: center;
    text-indent: 0px;
    font-size: 18px;
  }
  .navbar-nav {
    height: 0 !important;
    transition: 0.3s;

    &.show-navbar {
      height: 325px !important;
    }
  }
}
</style>
