<template>
  <div class="icon" v-if="isHomePage" v-lazy-container="{ selector: 'img' }"  :class="{'loading-img': images_data === undefined && !images_error}">
    <img v-if="images_data" :data-src="images_data.data['data']['full_url']" alt="">
    <img v-if="images_error" :data-src="require('../assets/img/logo-circle.png')" alt="" >
  </div>
  <div v-else class="default-img" v-lazy-container="{ selector: 'img' }" :class="className ? className : ''" :style="{width: width, height: height}">
    <img v-if="images_data" :data-src="images_data.data['data']['full_url']" alt="image">
    <img v-if="images_error" :data-src="defaultImg ? require('../assets/img/' + defaultImg) : require('../assets/img/default-img.jpg')" alt="image">
    <img v-else-if="!images_data && !images_error && images_data !== undefined" :data-src="undefined" alt="image">
  </div>
</template>

<script>
  import useSWRV from "swrv";
  import api from "../services/api";

  export default {
    name: "DefaultImage",
    props: ['id', 'width', 'height', 'className', 'defaultImg', 'forFilmPage', 'isHomePage'],
    setup(props) {
      if (props.id) {
        let {
          data: images_data,
          error: images_error,
        } = useSWRV(() => `/files/${props.id}?status=published`, api.fetcher);

        return {
          images_data,
          images_error,
        }
      } else {
        let images_data  = undefined,
            images_error = 'error';

        return {
          images_data,
          images_error
        }
      }
    },
  }
</script>

<style scoped lang="scss">
  .border-radius {
    border-radius: 50%;
  }

  .default-img.for-film-page {
    width: 100%;
    height: 100%;

    img {
      object-fit: contain;
      background-color: #000;

      &.default-gallery {
        object-fit: cover;
      }
    }
  }

  .default-img img {
    background: white;
  }

  .donate-page img {
    object-fit: contain !important;
    object-position: center !important;
  }

  @media (max-width: 768px) {
    #about .default-img {
      height: 500px !important;
    }
  }
</style>
