import Vue from "vue";
export default {
    token: () => {
        return Vue.axios
            .post(`auth/authenticate`,
                {
                    "email": "PeaceJam_api@oceanringtech.com",
                    "password": "p3AceJ4m#"
                })
            .then(res => {
                return res.data.data.token
            })
            .catch(() => null);
    },
    refresh: (token) => {
        return Vue.axios
            .post(`auth/refresh`,
                {
                  "token": token,
                },
                {
                  headers: {
                    "Authorization": `Bearer ${token}`
                  }
                })
            .then(res => {
              return res.data.data.token
            })
            .catch(() => null);
    }
}
