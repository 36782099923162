import Vue from "vue";
import getToken from "./getToken";
import axios from "axios";
let token = '';

async function getNewToken() {
  if (localStorage.getItem('accessToken')) {
    token = localStorage.getItem('accessToken');
  } else {
    token = await getToken.token();
    localStorage.setItem('accessToken', token);
    localStorage.setItem('expires', new Date());
  }
}

async function setToken() {
  token = await getToken.token();
  localStorage.setItem('accessToken', token);
  location.reload();
}

const checkExpiresTime = () => {
  const date1 = new Date() / 1000;
  const date2 = new Date(localStorage.getItem('expires')) / 1000;
  let diffTime = date2 - date1;
  diffTime += (10 * 60) // refresh 60 min after
  return diffTime > 1;
}

const getData = async (url) => {
  try {
    let data = await Vue.axios.get(url, {
      headers: {
        "Authorization": `Bearer ${token}`
      }
    });

    if (data.data.data) {
      data = data.data.data;
    }

    return {
      data
    };
  } catch (e) {
    let error = JSON.stringify(e);
    if (JSON.parse(error).message.replace(/[^0-9]/g, "") === '401') {
      await setToken();
    }
    return null
  }
}

export default {

  async fetcher(url) {

    await getNewToken();

    if (checkExpiresTime()) {
      return getData(url)
    } else {
      try {
        token = await getToken.refresh(token)
        localStorage.setItem('accessToken', token);
        localStorage.setItem('expires', new Date());
        return getData(url)
      } catch (e) {
        token = await getToken.token();
        localStorage.setItem('accessToken', token);
        localStorage.setItem('expires', new Date());
        return getData(url)
      }
    }
  },

  getLinkList: async (id) => {
    await getNewToken();

    return Vue.axios
        .get(`items/film_links?status=published&filter[film]=${id}`,
            {
              headers: {
                "Authorization": `Bearer ${token}`
              }
            })
        .then(res => res.data.data)
        .catch( async () => {
          await setToken();
          return null
        });
  },
  getVimeoVideoId: async (url) => {
    return Vue.axios
        .get(`https://vimeo.com/api/oembed.json?url=${url}`)
        .then(res => res)
        .catch(() => null);
  },

  getAllImageUrl: async (props) => {
    await getNewToken();
    return Vue.axios
        .get(`files?${props}`, {
          headers: {
            "Authorization": `Bearer ${token}`
          }
        })
        .then(res => res.data.data)
        .catch(() => null);
  },

  getNobelList: async (limit) => {
    await getNewToken();
    return Vue.axios
        .get(`items/nobel_list?status=published&limit=${limit}&sort=?`, {
          headers: {
            "Authorization": `Bearer ${token}`
          }
        })
        .then(res => res.data.data)
        .catch(() => []);
  },

  paymentIntent: async (amount, description) => {
    return axios.post('https://api-billionacts.azurewebsites.net/api/PeaceJamPayment/peacejam-paymentIntent',
      {amount, description},
      {headers: {'Accept': 'application/json'}
      }).then((data) => {
        return data
      }).catch(() => {
        return 'error'
      })
  }
}
