<template>
  <div class="footer-map-slider">
    <carousel
      :items="1"
      :dots="false"
      :margin="30"
      :nav="false"
      :autoplay="true"
    >
      <div class="slider-items">
        <img src="../assets/img/map-bg.svg" width="334" height="240" alt="">
      </div>
      <router-link :to="'/contact?l=' + item.country_name.country_code" class="slider-items" v-for="item in locations.data" :key="item.id">
        <h3>{{item.title}}</h3>
        <DefaultImage :id="item.image.id" width="240px" height="160px" class="mb-3" />
        <p v-if="item.address">
          <i class="fa fa-crosshairs"></i>
          {{item.country}}, {{item.address}}
        </p>

        <p v-if="item.email">
          <i class="fa fa-envelope"></i>
          {{item.email}}
        </p>

        <p v-if="item.phone">
          <i class="flaticon-call"></i>
          {{item.phone}}
        </p>
      </router-link>
    </carousel>
  </div>
</template>

<script>
  import carousel from 'vue-owl-carousel';
  import DefaultImage from "./DefaultImage";

  export default {
    name: "MapFooter",
    props: ['locations'],
    components: {
      DefaultImage,
      carousel
    }
  }
</script>

<style scoped lang="scss">
  .footer-map-slider {

    .slider-items {
      display: block;
      padding: 0 3px;
    }

    p {
      color: #fff;

      i {
        color: #fc4040;
      }
    }

    h3 {
      position: relative;
      margin-left: 10px;
      font-size: 25px;
      color: #ffffff;
      margin-bottom: 30px;

      &::before {
        content: "";
        position: absolute;
        height: 100%;
        width: 2px;
        background-color: #31bfd7;
        left: -12px;
      }
    }
  }
</style>
